@font-face {
  font-family: "Bold";
  src: local("GothamBold"), url("./assets/Gotham-Spotify-Font/GothamBold.ttf") format("truetype");
}

@font-face {
  font-family: "Light";
  src: local("GothamLight"), url("./assets/Gotham-Spotify-Font/GothamLight.ttf") format("truetype");
}

:root {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}


body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}


